@import "@/assets/scss/style.scss";

.c-user-settings__Wrapper {
    margin-top: 40px;
    padding: 24px;
    margin: auto;
}
.c-user-settings {
    &__segment {
        padding: 20px;
        background-color: #ffffff;
        border-radius: 12px;
        margin-bottom: 16px;

        &:last-child {
            margin-bottom: 16px;
        }
    }

    &__inner {
        display: flex;
        justify-content: space-between;
        margin-top: 3rem;
    }

    &__dropdown {
        width: 100%;
    }

    &__input {
        margin-bottom: 16px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__save-button {
        margin-top: 24px;
        display: block;
    }

    &__header {
        flex-direction: row;
        box-sizing: border-box;
        align-items: center;
        display: flex;
        margin-bottom: -20px;
    }
    &__logout {
        max-width: 120px;
        display: block;
        margin-right: 30%;
    }

    &__user-icon {
        float: left;
    }

    &__user-text {
        box-sizing: border-box;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-left: 22px;
        flex: 1 1 auto;

        @media screen and (max-width: 500px) {
            margin-left: 0;
        }
    }

    &__flex-col {
        width: 290px;
        margin-top: 10px;
    }

    &__flex-width {
        width: 100%;
    }

    &__footer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .app-version__wrapper {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            margin-right: 2rem;
            .app-version__text {
                font-size: 0.9rem;
                font-weight: bold;
                color: #4e4e4e;
            }

            .cache-reset__icon {
                font-size: 1.2rem;
                color: $roltek-blue;
                cursor: pointer;
                animation-play-state: paused;

                &.clicked {
                    animation: spin 2s linear infinite;
                    animation-play-state: running;
                }
            }

            @keyframes spin {
                from {
                    transform: rotate(0deg);
                }
                to {
                    transform: rotate(360deg);
                }
            }
        }
    }
}
