@import "@/assets/scss/style.scss";

.action-buttons .action-btn{
    margin: 0 5px;
}
.client-top-bar {
    display: flex;
    width: auto;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    background: #FFF;
    border: 1px solid #E8E8E8;
    padding: 0 5%;
    margin-bottom: 40px;
}

.left-side-actions {
    display: flex;
    align-items: center;
    justify-content: center;

    .product-info {
        padding: 0 1.50rem;
        
        .path-before {
            font-size: 0.960rem;
            font-weight: 400;
            color: $grey-7;
        }

        .path-current{
            font-size: 0.960rem;
            font-weight: 450;
            color: #262626;
        }

        .product-name {
            font-size: 1.125rem;
            font-weight: bold;
            color: $grey-9;
        }
    }
}
.misc {
    padding: 0 8px;
}

.icon-color {
    color: $grey-9;
}
