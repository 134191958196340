@import "@/assets/scss/style.scss";

.c-language-picker {
    &__select {
        //width: 300px;
        width: 100%;
    }

    &__save-button {
        margin-top: 24px;
        display: block;
    }
}
