@import "@/assets/scss/style.scss";

.settings {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    ::v-deep .left-side-actions .product-info .path-current {
        font-weight: bold;
    }
    .settings-wrapper {
        width: 50vw;
        margin-top: 3rem;
        border: 1px solid #e8e8e8;
        border-radius: 10px;
    }
}

.isLoading {
    opacity: 0.6;

    &::v-deep {
        .ant-spin {
            display: flex;
            position: absolute;
            justify-content: center;
            align-items: center;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: 50;
        }
    }
}

// responsive layout
@media (max-width: 1200px) {
    .settings {
        .settings-wrapper {
            width: 70vw;
        }
    }
}
